/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Home Screen
Brief : Purpose: This code represents the Home screen of the "wikibedtimestories.com" website.
Functionality:
Components:
Includes the <Header />, <Footer />, and <HomeContent /> components.
State Management:
Uses various state variables to manage the data, loading states, and page numbers for story content.
Fetching Data:
Utilizes the useEffect hook to fetch data (stories) from a controller (StoryListController) based on specific conditions like page number and load status.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */
import React, { useState, useEffect, useRef } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import HomeContent from './HomeContent'
import fetchData from '../../../../../../Controller/WS-WrittenStories/StoryListController';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function StoriesList() {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [allStoriesLoaded, setAllStoriesLoaded] = useState(false);
    const [firstAndLastStoriesNumber, setFirstAndLastStoriesNumber] = useState(0);
    const [data, setData] = useState([]);
    const [sortby, setSortBy] = useState('last_approved_date');
    // const [page, setPage] = useState(1);
    const [totalStories, setTotalStories] = useState(0);
    const [totalStoriesObject, setTotalStoriesObject] = useState(0);
    const [loading, setLoading] = useState(false);
    const [apiCallCompleted, setApiCallCompleted] = useState(false);
    const [selectedAge, setSelectedAge] = useState(null);
    const apiCallInProgress = useRef(false);
    const [page, setPage] = useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageNumber = parseInt(searchParams.get('page'), 10);
        return !isNaN(pageNumber) ? pageNumber : 1;
    });

    useEffect(() => {
        setData([]);
        setLoading(true);
        fetchData(page, sortby, userId,  selectedAge,allStoriesLoaded,setSortBy, setData, setTotalStories, setTotalStoriesObject, setFirstAndLastStoriesNumber, setLoading)
            .then(() => {
                setApiCallCompleted(true);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setApiCallCompleted(true);
                setLoading(false);
            });
    }, [page, sortby, userId,selectedAge, allStoriesLoaded]);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageNumber = parseInt(searchParams.get('page'), 10);
        if (!isNaN(pageNumber)) {
            setPage(pageNumber);
        } else {
            setPage(1); // Default to page 1 if no valid page number is present
        }
    }, [location.search]);

    const PAGE_LIMIT = 10; // Your page limit constant
    const [totalPages, setTotalPages] = useState(1); // State to store total pages

    useEffect(() => {
        const calculateTotalPages = () => {
            if (!allStoriesLoaded && totalStories) {
                const totalPagesCount = Math.ceil(totalStories / PAGE_LIMIT);
                setTotalPages(totalPagesCount);
            }
        };

        calculateTotalPages();
    }, [allStoriesLoaded, totalStories]);

    return (
        <div>
            <Header totalStories={totalStories} authToken={(location.state) ? location.state.authToken : null} />
            <Helmet>
            <title>Wiki Bedtime Stories: Short bedtime stories for kids</title>
                <meta name="description"
                    content="Write, read, and listen to free short bedtime stories for kids. Enjoy fairy tales, sleeping beauty, Ramayana, Greek mythology, Panchatantra, and more bedtime stories for ages 5 to 12." />

             
            </Helmet>
            <HomeContent data={data} allStoriesLoaded={allStoriesLoaded}
                loading={loading}
                setPage={setPage} page={page} setSortBy={setSortBy} setData={setData} setAllStoriesLoaded={setAllStoriesLoaded} totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                firstAndLastStoriesNumber={firstAndLastStoriesNumber}
                apiCallInProgress={apiCallInProgress}
                totalPages={totalPages}
                apiCallCompleted={apiCallCompleted}
                setApiCallCompleted={setApiCallCompleted}
                selectedAge={selectedAge}
                setSelectedAge={setSelectedAge}
            />
            <Footer />
        </div>
    )
}

export default StoriesList
