/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-25-2023
Purpose : Writing Part in Contribution Network
Brief :
Developer : Jayash Todi.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import downArrow from '../../../Assets/Arrow - Right 2.webp';
import languages from '../../../Assets/language.png';
import profile from '../../../Assets/Add User.webp';

import downloadIcon from '../../../Assets/Download1.webp';
import ContributionNetworkFinalPage from '../ContributionNetworkFinalPage';
import { CN_DraftPatch, CN_DraftStoryIdGET } from '../../../../../../Model/CN-ContributionNetwork/ContributionNetwork';
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header';
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer';
import axios from 'axios';
import ModeratorPreview from './ModeratorPreview';

const ModeratorEdit = (props) => {

    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const { storyID, authorUserID } = useParams();
    // console.log(authorUserID)
    const [storyImg, setStoryImg] = useState();
    //Title
    const title = useRef(null);
    const [titleValue, settitleValue] = useState("");
    useEffect(() => {
        title.current.style.height = "0px";
        const scrollHeight = title.current.scrollHeight;
        title.current.style.height = scrollHeight + "px";
    }, [titleValue]);

    //Beginning
    const beginning = useRef(null);
    const [beginningValue, setBeginningValue] = useState("");
    useEffect(() => {
        beginning.current.style.height = "0px";
        const scrollHeight = beginning.current.scrollHeight;
        beginning.current.style.height = scrollHeight + "px";
    }, [beginningValue]);

    //Story
    const story = useRef(null);
    const [storyValue, setStoryValue] = useState("");
    useEffect(() => {
        story.current.style.height = "0px";
        const scrollHeight = story.current.scrollHeight;
        story.current.style.height = scrollHeight + "px";
    }, [storyValue]);

    //Message
    const message = useRef(null);
    const [messageValue, setMessageValue] = useState("");
    useEffect(() => {
        message.current.style.height = "0px";
        const scrollHeight = message.current.scrollHeight;
        message.current.style.height = scrollHeight + "px";
    }, [messageValue]);
    //alttext
    const alt_text = useRef(null);
    const [altImage, setAltImage] = useState("");
    useEffect(() => {
        alt_text.current.style.height = "0px";
        const scrollHeight = alt_text.current.scrollHeight;
        alt_text.current.style.height = scrollHeight + "px";
    }, [altImage]);
    //metades
    const meta_des = useRef(null);
    const [metaDes, setMetaDes] = useState("");
    useEffect(() => {
        meta_des.current.style.height = "0px";
        const scrollHeight = alt_text.current.scrollHeight;
        meta_des.current.style.height = scrollHeight + "px";
    }, [metaDes]);
    useEffect(() => {
        axios.get(`${CN_DraftStoryIdGET}?storyId=${storyID}`)
            .then(response => {
                const storyData = response.data;
                // Log the entire storyData object to inspect its structure
                // console.log('Full storyData:', storyData);

                // Access the first element of the data array
                const storyDetails = storyData.data[0];



                // Set state values if the data is fetched correctly
                settitleValue(storyDetails.title || ''); // Provide default value if undefined
                setBeginningValue(storyDetails.summary || ''); // Provide default value if undefined
                setStoryValue(storyDetails.story || ''); // Provide default value if undefined
                setMessageValue(storyDetails.conclusion || ''); // Provide default value if undefined
                setAltImage(storyDetails.alt_text || '');
                setMetaDes(storyDetails.meta_description || '');
                setStoryImg(storyDetails.storyThumbnailImage || '');
            })
            .catch(error => {
                console.error('Error fetching story details:', error);
            });
    }, [storyID]);
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Check if any of the textareas are empty
        if (titleValue.trim() === '') {
            alert('Please add a Title before submitting.');
            return; // Stop form submission
        }
        if (beginningValue.trim() === '') {
            alert('Please add an Intro before submitting.');
            return; // Stop form submission
        }
        if (storyValue.trim() === '') {
            alert('Please add a Story before submitting.');
            return; // Stop form submission
        }



        if (messageValue.trim() === '') {
            alert('Please add a Moral before submitting.');
            return; // Stop form submission
        }
        const submitUrl = CN_DraftPatch;
        // console.log(storyID)
        // console.log(altImage)
        const data = {
            title: titleValue,
            story: storyValue,
            conclusion: messageValue,
            summary: beginningValue,
            alt_text: altImage,
            meta_description: metaDes,
            status: 2, // Status 1 for draft
            language: '1',
            created_by_id: '2',
            category_main: 'ram',
            category_secondary: 'ram,lav,kush',
            // image_name: '1',
            age_range_id: "",
            story_id: String(storyID)
        };
        const response = await fetch(submitUrl, {
            method: 'PATCH', // note the lowercase 'method'
            headers: {
                Authorization: authToken,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            cache: 'default'
        });

        if (response.ok) {
            const jsonData = await response.json();
            setStoryId(Number(jsonData.data.story_id));
        } else {
            console.error('Request failed with status:', response.status);
        }
        setIsComponentVisible(!isComponentVisible);
    }

    const handleSaveDraft = async (e) => {
        const submitUrl = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story`;
        const data = {
            title: titleValue,
            story: storyValue,
            conclusion: messageValue,
            summary: beginningValue,
            alt_text: altImage,
            meta_description: metaDes,
            status: 1, // Status 1 for draft
            language: '1',
            created_by_id: '2',
            category_main: 'ram',
            category_secondary: 'ram,lav,kush',
            // image_name: '1',

            age_range_id: "",
            story_id: String(storyID)
        };
        try {
            const response = await fetch(CN_DraftPatch, {
                method: 'PATCH', // note the lowercase 'method'
                headers: {
                    Authorization: authToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                cache: 'default'
            });

            if (response.ok) {
                const jsonData = await response.json();
                // console.log(jsonData);
                setStoryId(Number(jsonData.data.story_id));
                alert('Draft saved successfully!'); // Display an alert
            } else {
                console.error('Request failed with status:', response.status);
                alert('Failed to save draft. Please try again.'); // Display an error alert
            }
        } catch (error) {
            console.error('An error occurred:', error);
            alert('An error occurred. Please try again.'); // Display a generic error alert
        }
    }


    const [titlePlaceholder, setTitlePlaceholder] = useState('');
    const [beginningPlaceholder, setBeginningPlaceholder] = useState('');
    const [storyPlaceholder, setStoryPlaceholder] = useState('');
    const [messagePlaceholder, setMessagePlaceholder] = useState('');

    const changeLangaugeToHindi = () => {
        setSelectLanguageDropdown(false);
        setTitlePlaceholder('');
        setBeginningPlaceholder('');
        setStoryPlaceholder('');
        setMessagePlaceholder('');
    }

    const changeLangaugeToEnglish = () => {
        setSelectLanguageDropdown(false);
        setTitlePlaceholder('');
        setBeginningPlaceholder('');
        setStoryPlaceholder('');
        setMessagePlaceholder('');
    }


    const [selectLanguageDropdown, setSelectLanguageDropdown] = useState(false);

    const openSelectLangDropdown = () => {
        setSelectLanguageDropdown(!selectLanguageDropdown);
    }



    const [storyId, setStoryId] = useState(0);
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const maxTitleWordCount = 3000;
    const maxStoryWordCount = 5000;
    const maxMessageWordCount = 250;
    const handleTitleTextareaChange = (event) => {
        const inputTitle = event.target.value;
        const wordCount = inputTitle.trim().split(/\s+/).length;

        if (wordCount <= maxTitleWordCount) {
            settitleValue(inputTitle);
        }
    };
    const handleIntroTextareaChange = (event) => {
        const inputIntro = event.target.value;
        const wordCount = inputIntro.trim().split(/\s+/).length;

        if (wordCount <= maxTitleWordCount) {
            setBeginningValue(inputIntro);
        }
    };
    const getIntroWordCount = (text) => {
        const wordCount = text.trim().split(/\s+/).length;
        return text ? Math.min(wordCount, maxTitleWordCount) : 0;
    };
    const handleStoryChange = (event) => {
        const inputSummary = event.target.value;
        const wordCount = inputSummary.trim().split(/\s+/).length;

        if (wordCount <= maxStoryWordCount) {
            setStoryValue(inputSummary);
        }
    };
    const getStoryWordCount = (text) => {
        const wordCount = text.trim().split(/\s+/).length;
        return text ? Math.min(wordCount, maxTitleWordCount) : 0;
    };
    const handleMessageChange = (event) => {
        const inputConclusion = event.target.value;
        const wordCount = inputConclusion.trim().split(/\s+/).length;

        if (wordCount <= maxMessageWordCount) {
            setMessageValue(inputConclusion);
        }
    };
    const handleAltText = (event) => {
        const inputAlttext = event.target.value;
        const wordCount = inputAlttext.trim().split(/\s+/).length;
        if (wordCount <= maxMessageWordCount) {
            setAltImage(inputAlttext);
        }
    }
    const handleMetaDes = (event) => {
        const inputMetades = event.target.value;
        const wordCount = inputMetades.trim().split(/\s+/).length;
        if (wordCount <= maxMessageWordCount) {
            setMetaDes(inputMetades);
        }
    }
    const getMessageWordCount = (text) => {
        const wordCount = text.trim().split(/\s+/).length;
        return text ? Math.min(wordCount, maxTitleWordCount) : 0;
    };
    return (
        <>
            <div id='CN-WS'>

                {isComponentVisible && <div onClick={() => setIsComponentVisible(!isComponentVisible)} id='popup-overlay'>
                </div>}
                <Header />

                <div className='MainPart'>
                    <div className='CustomBar'>
                        <div>
                            Dedicated to:
                        </div>

                        <button id='btn-add-people'>
                            <img alt='profile' src={profile} />
                            {/* Add people */}
                            Everyone
                            <img alt='down' id="side-dropdown" src={downArrow} />
                        </button>

                        <button onClick={openSelectLangDropdown} id='btn-select-lang'>
                            <img alt='languages' src={languages} />
                            Select Language
                            <img alt='down' src={downArrow} />
                        </button>

                        {selectLanguageDropdown && <div id='select-lang-dropdown'>
                            <div onClick={() => changeLangaugeToHindi()}>हिन्दी</div>
                            <div onClick={() => changeLangaugeToEnglish()}>English</div>
                        </div>}

                    </div>
                    <div className='cn-Draftbtn'>
                        {/* <button id='undo'><img src={undo} alt='undo' />Undo</button>
                        <button id='redo'><img src={redo} alt='redo' />Redo</button> */}
                        <button onClick={handleSaveDraft} id='saveDraftBtn'><img alt='downloadicon' src={downloadIcon} />Save Draft</button>

                    </div>

                    <div className='WritingPart'>
                        <div className='CN-Title'>
                            <div className='cn-title-heading'>Title

                            </div>
                            <textarea ref={title}
                                value={titleValue}
                                onChange={handleTitleTextareaChange}
                                id='cn-title' type='text' placeholder={titlePlaceholder} />

                        </div>
                        <div className='cn-intro'>
                            <div className='cn-intro-heading'>
                                Intro
                                <div className='cn-into-count'>{`${getIntroWordCount(beginningValue)}/${maxTitleWordCount}`}</div>
                            </div>
                            <textarea
                                value={beginningValue}
                                ref={beginning}
                                onChange={handleIntroTextareaChange}
                                id='cn-beginning' type='text' placeholder={beginningPlaceholder} />

                        </div>
                        <div className='cn-mainstory'>
                            <div className='cn-mainstory-heading'>Main Story
                                <div className='cn-main-count'>{`${getStoryWordCount(storyValue)}/${maxStoryWordCount}`}</div>
                            </div>
                            <textarea
                                ref={story}
                                value={storyValue}
                                onChange={handleStoryChange}
                                id='cn-story' type='text' placeholder={storyPlaceholder} />

                        </div>
                        <div className='cn-summary'>
                            <div className='cn-summary-heading'>Moral
                                <div className='cn-moral-count'>{`${getMessageWordCount(messageValue)}/${maxMessageWordCount}`}</div>
                            </div>
                            <textarea
                                ref={message}
                                value={messageValue}
                                onChange={handleMessageChange}
                                id='cn-message' type='text' placeholder={messagePlaceholder} />

                        </div>
                        <div className='cn-summary'>
                            <div className='cn-summary-heading'>Alt Text
                                {/* <div className='cn-moral-count'>{`${getMessageWordCount(messageValue)}/${maxMessageWordCount}`}</div> */}
                            </div>
                            <textarea
                                ref={alt_text}
                                value={altImage}
                                onChange={handleAltText}
                                id='cn-message' type='text' placeholder={messagePlaceholder} />

                        </div>
                        <div className='cn-summary'>
                            <div className='cn-summary-heading'>Meta Description
                                {/* <div className='cn-moral-count'>{`${getMessageWordCount(messageValue)}/${maxMessageWordCount}`}</div> */}
                            </div>
                            <textarea
                                ref={meta_des}
                                value={metaDes}
                                onChange={handleMetaDes}
                                id='cn-message' type='text' placeholder={messagePlaceholder} />

                        </div>
                    </div>
                    <div className='cn-submitbtn'>
                        <button onClick={handleSubmit} id='submitBtn'>Next</button>

                    </div>

                </div>
                {isComponentVisible && <ModeratorPreview authToken={authToken} isComponentVisible={setIsComponentVisible} authorUserID={authorUserID} storyId={storyId} storyID={storyID} storyImg={storyImg} storyData={storyValue} messageData={messageValue} beginningData={beginningValue} titleData={titleValue} altData={altImage} metaData={metaDes} />}
                <Footer />
            </div>


            <ContributionNetworkFinalPage />
        </>
    )
}

export default ModeratorEdit;