/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Jayesh Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react';
import '../../CS/AuthorCard.css';
import sample from '../../../Assets/Ellipse 129.png';
import following from '../../../Assets/AuthorProfile/following.svg';
import stories from '../../../Assets/AuthorProfile/Document.svg';
import followers from '../../../Assets/AuthorProfile/3 User.svg';
import audioStories from '../../../Assets/AuthorProfile/Play.svg';
import follow from '../../../Assets/AuthorProfile/Add User.svg';
import addToFav from '../../../Assets/AuthorProfile/Star.svg';
import followAuthor from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileFollow';
import { useLocation, useNavigate } from 'react-router-dom';
import favoriteAuthor from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileFavorite';
import followersPurple from '../../../Assets/AuthorProfile/followersPurple.svg';
import followingPurple from '../../../Assets/AuthorProfile/followingPurple.svg';
import storiesPurple from '../../../Assets/AuthorProfile/StoriesPurple.svg';
import audioStoriesPurple from '../../../Assets/AuthorProfile/AudioStoriesPurple.svg';
import defaultImage from '../../../../WS-WrittenStories/Assets/Images/defaultprofile.webp'
import unfavoriteAuthor from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileUnfav';
import unfollowAuthor from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileUnFollow';

const AuthorCard = ({ data, authorId, setData, setDisplayList, displayList, setPage, setAuthorProfilePublicationsData, setAuthorProfileAudioData, setAuthorProfileFollowersData, setAuthorProfileFollowingData
    , fetchAuthorProfileData // Accept the function as a prop
}) => {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const navigate = useNavigate();
    const [isFollowing, setIsFollowing] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    const handleFollowClick = () => {
        if (authToken === null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            followAuthor(authorId, authToken);
            setIsFollowing(true);
            // Fetch the updated author profile data
            fetchAuthorProfileData();
        }
    }
    const handleUnfollowClick = () => {
        if (authToken === null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            unfollowAuthor(authorId, authToken);
            setIsFollowing(false);
            // Fetch the updated author profile data
            fetchAuthorProfileData();
        }
    }
    const unfavorAuthor = (authorid) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            unfavoriteAuthor(authorId, authToken);
            setIsFavorite(false);
        }
    };
    const handleFavoriteClick = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            favoriteAuthor(authorId, authToken);
            setIsFavorite(true);
        }
    }

    const setAllArraysAsEmpty = () => {
        setAuthorProfileAudioData([]);
        setAuthorProfileFollowersData([]);
        setAuthorProfileFollowingData([]);
        setAuthorProfilePublicationsData([]);
    }

    useEffect(() => {
        if (displayList === 0) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
        } else if (displayList === 1) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#593ECC';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#593ECC';
        } else if (displayList === 2) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
        } else if (displayList === 3) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
        } else {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
        }
    })

    return (
        <div>
            <div className='web'>
                <div className='AuthorProfileLeftCard'>
                    <div className='AuthorProfileLeftCardFirst'>
                        <img src={data.ImageName} alt="Author" />
                    </div>
                    <div className='AuthorProfileLeftCardSecond'>
                        <div>
                            <span className='AuthorProfileLeftCardSecondName'>{data.Name}</span>
                        </div>

                        <div>
                            <span className='AuthorProfileLeftCardSecondTitle'>Author</span>
                        </div>
                    </div>

                    <div className='AuthorProfileLeftCardThird'></div>

                    <div className='AuthorProfileLeftCardFourth'>
                        <div className='AuthorProfileLeftCardFourthUpper'>
                            <button>
                                <div>
                                    <span>
                                        <img src={follow} />
                                    </span>
                                    {!isFollowing && !data.is_following ? (
                                        <span onClick={() => handleFollowClick()}>
                                            Follow
                                        </span>
                                    ) : (
                                        <span onClick={() => handleUnfollowClick()}>
                                            Following
                                        </span>
                                    )
                                    }
                                </div>
                            </button>
                        </div>

                        <div className='AuthorProfileLeftCardFourthLower'>
                            <button>
                                <div>
                                    <span>
                                        <img src={addToFav} />
                                    </span>
                                    {!isFavorite && !data.is_favorite ? (
                                        <span onClick={handleFavoriteClick}>
                                            Add to favorite
                                        </span>
                                    ) : (
                                        <span onClick={unfavorAuthor}>
                                            Added to favorite
                                        </span>
                                    )}
                                </div>
                            </button>
                        </div>
                    </div>

                    <div className='AuthorProfileLeftCardThird'></div>

                    <div className='AuthorProfileLeftCardFifth'>
                        <div onClick={() => { setDisplayList(1); setPage(1); setAllArraysAsEmpty(); }}>
                            <img id='AuthorProfileLeftCardFifthFollowersImg' src={(displayList === 1) ? followersPurple : followers} />
                            <span id='AuthorProfileLeftCardFifthFollowers' className='AuthorProfileLeftCardFifthTag'>Followers</span>
                            <span id='AuthorProfileLeftCardFifthFollowers2'>({data.followers_count})</span>
                        </div>

                        <div onClick={() => { setDisplayList(2); setPage(1); setAllArraysAsEmpty(); }}>
                            <img id='AuthorProfileLeftCardFifthFollowingImg' src={(displayList === 2) ? followingPurple : following} />
                            <span id='AuthorProfileLeftCardFifthFollowing' className='AuthorProfileLeftCardFifthTag'>Following</span>
                            <span id='AuthorProfileLeftCardFifthFollowing2'> ({data.following_count})</span>
                        </div>

                        <div onClick={() => { setDisplayList(0); setPage(1); setAllArraysAsEmpty(); }}>
                            <img id='AuthorProfileLeftCardFifthStoriesImg' src={(displayList === 0) ? storiesPurple : stories} />
                            <span id='AuthorProfileLeftCardFifthStories' className='AuthorProfileLeftCardFifthTag'>Stories</span>
                            <span id='AuthorProfileLeftCardFifthStories2'> ({data.total_stories})</span>
                        </div>

                        <div onClick={() => { setDisplayList(3); setPage(1); setAllArraysAsEmpty(); }}>
                            <img id='AuthorProfileLeftCardFifthAudioImg' src={(displayList === 3) ? audioStoriesPurple : audioStories} />
                            <span id='AuthorProfileLeftCardFifthAudio' className='AuthorProfileLeftCardFifthTag'>Audio stories</span>
                            <span id='AuthorProfileLeftCardFifthAudio2'> ({data.audio_story_count})</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className='mobile'>
                <div className='AuthorProfilePicture'>
                    <img src={data.ImageName} alt="Author" />
                </div>
                <div>
                    <div className='AuthorProfileName'>{data.Name}</div>
                </div>

                <div>
                    <div className='AuthorProfileDesign'>Author</div>
                </div>
                {data.
                    is_biography
                    ?
                    <div className='AuthorDescriptionBio'>   {data.biography}</div> :
                    <div></div>
                }
                <div className='AuthorProfileFollow'>
                    <div onClick={() => { setDisplayList(1); setPage(1); setAllArraysAsEmpty(); }}>
                        <span id='AuthorProfileLeftCardFifthFollowers2'>{data.followers_count}</span>
                        <span id='AuthorProfileLeftCardFifthFollowers' className='AuthorProfileFollower'>Followers</span>
                    </div>
                    <span className='AuthorDot'>.</span>
                    <div onClick={() => { setDisplayList(2); setPage(1); setAllArraysAsEmpty(); }}>
                        <span id='AuthorProfileLeftCardFifthFollowing2'> {data.following_count}</span>
                        <span id='AuthorProfileLeftCardFifthFollowing' className='AuthorProfileFollowing'>Following</span>
                    </div>
                </div>
                <div className='AuthorProfileFavFollow'>
                    <div className='AuthorProfileLeftCardFourthUpper'>
                        <button>
                            <div>
                                <span>
                                    <img src={follow} />
                                </span>
                                {!isFollowing && !data.is_following ? (
                                    <span onClick={() => handleFollowClick()}>
                                        Follow
                                    </span>
                                ) : (
                                    <span onClick={() => handleUnfollowClick()}>
                                        Following
                                    </span>
                                )
                                }
                            </div>
                        </button>
                    </div>

                    <div className='AuthorProfileLeftCardFourthLower'>
                        <button>
                            <div>
                                <span>
                                    <img src={addToFav} />
                                </span>
                                {!isFavorite && !data.is_favorite ? (
                                    <span onClick={handleFavoriteClick}>
                                        Add to favorite
                                    </span>
                                ) : (
                                    <span onClick={unfavorAuthor}>
                                        Added to favorite
                                    </span>
                                )}
                            </div>
                        </button>
                    </div>
                </div>
                {/* <div onClick={() => { setDisplayList(0); setPage(1); setAllArraysAsEmpty(); }}>
                    <img id='AuthorProfileLeftCardFifthStoriesImg' src={(displayList === 0) ? storiesPurple : stories} />
                    <span id='AuthorProfileLeftCardFifthStories' className='AuthorProfileLeftCardFifthTag'>Stories</span>
                    <span id='AuthorProfileLeftCardFifthStories2'> ({data.total_stories})</span>
                </div>

                <div onClick={() => { setDisplayList(3); setPage(1); setAllArraysAsEmpty(); }}>
                    <img id='AuthorProfileLeftCardFifthAudioImg' src={(displayList === 3) ? audioStoriesPurple : audioStories} />
                    <span id='AuthorProfileLeftCardFifthAudio' className='AuthorProfileLeftCardFifthTag'>Audio stories</span>
                    <span id='AuthorProfileLeftCardFifthAudio2'> ({data.audio_story_count})</span>
                </div> */}
                <div className='authorprofiletab'>
                    <div
                        onClick={() => { setDisplayList(0); setPage(1); setAllArraysAsEmpty(); }}
                        className={`button-style ${displayList === 0 ? 'selected' : 'not-selected'}`}
                    >
                        <img id='AuthorProfileLeftCardFifthStoriesImg' src={(displayList === 0) ? storiesPurple : stories} />
                        <span
                            id='AuthorProfileLeftCardFifthStories'
                            className={`AuthorProfileLeftCardFifthTag ${displayList === 0 ? 'text-selected' : 'text-not-selected'}`}
                        >
                            Stories
                        </span>
                        <span id='AuthorProfileLeftCardFifthStories2' className={displayList === 0 ? 'text-selected' : 'text-not-selected'}>
                            ({data.total_stories})
                        </span>
                    </div>

                    <div
                        onClick={() => { setDisplayList(3); setPage(1); setAllArraysAsEmpty(); }}
                        className={`button-style ${displayList === 3 ? 'selected' : 'not-selected'}`}
                    >
                        <img id='AuthorProfileLeftCardFifthAudioImg' src={(displayList === 3) ? audioStoriesPurple : audioStories} />
                        <span
                            id='AuthorProfileLeftCardFifthAudio'
                            className={`AuthorProfileLeftCardFifthTag ${displayList === 3 ? 'text-selected' : 'text-not-selected'}`}
                        >
                            Audio stories
                        </span>
                        <span id='AuthorProfileLeftCardFifthAudio2' className={displayList === 3 ? 'text-selected' : 'text-not-selected'}>
                            ({data.audio_story_count})
                        </span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AuthorCard