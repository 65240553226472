/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
import UserCard from './UserCard'
import '../../CS/UserProfile/UserProfile.css'
import AuthorPageSearchBar from '../../../../CN-ContributionNetwork/src/JS/AuthorProfile/AuthorPageSearchBar'
import UserDescriptionCard from './UserDescriptionCard'
import UserPublications from './UserPublications'
import { useLocation } from 'react-router-dom'
import fetchAuthorProfileFollowers from '../../../../../../Controller/UM-UserManagement/UserProfile/UserProfileFollowing'
import fetchAuthorProfileFollowing from '../../../../../../Controller/UM-UserManagement/UserProfile/UserProfileFollowers'
import fetchUserProfileData from '../../../../../../Controller/UM-UserManagement/UserProfile/UserDescription'
import fetchUserProfileDraftData from '../../../../../../Controller/UM-UserManagement/UserProfile/UserDraftStories'
import UserSocialMediaCard from './UserSocialMediaCard'
import fetchUserProfilePublicationsData from '../../../../../../Controller/UM-UserManagement/UserProfile/UserProfilePublications'
import fetchUserProfileSaved from '../../../../../../Controller/UM-UserManagement/UserProfile/UserSavedStories'
import fetchUserProfileLiked from '../../../../../../Controller/UM-UserManagement/UserProfile/UserLikedStories'
import fetchUserProfileAudioData from '../../../../../../Controller/UM-UserManagement/UserProfile/UserAudioStories'
import fetchUserProfileReviewData from '../../../../../../Controller/UM-UserManagement/UserProfile/UserReviewStories'
import fetchUserProfileApprovedData from '../../../../../../Controller/UM-UserManagement/UserProfile/UserApprovedStories'

const UserProfile = () => {
  const location = useLocation();
  const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
  const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
  useEffect(() => {
    // Update component state with values from localStorage on mount
    setAuthToken(localStorage.getItem('token') || null);
    setUserID(localStorage.getItem('UserID') || null);
  }, []);
  const [page, setPage] = useState(1);
  const [totalStories, setTotalStories] = useState(0);
  const currentUrl = window.location.href;
  let authorId = '';
  for (let i = currentUrl.length - 1; i >= 0; i--) {
    if (currentUrl[i] >= '0' && currentUrl[i] <= '9') {
      authorId = currentUrl[i] + authorId;
    } else {
      break;
    }
  }
  const [userProfileData, setUserProfileData] = useState({
    Name: '',
    author_id: Number(authorId),
    biography: '',
    author_image: '',
    facebook_url: "",
    instagram_url: "",
    linkedin_url: "",
    twitter_url: "",
    social_media_status: false,
    total_stories: 0,
    total_views: 0,
    rating: "0",
    total_likes: 0,
    audio_story_count: 0,
    followers_count: 0,
    following_count: 0,
    add_to_favorite: 0,
    is_following: false,
    is_favorite: false
  });

  const [userProfilePublicationsData, setUserProfilePublicationsData] = useState([]);
  const [userProfileDraftData, setUserProfileDraftData] = useState([]);
  const [userProfileReviewData, setUserProfileReviewData] = useState([]);
  const [userProfileApprovedData, setUserProfileApprovedData] = useState([]);
  const [userProfileLiked, setUserProfileLiked] = useState([]);
  const [userProfileSaved, setUserProfileSaved] = useState([]);
  const [authorProfileAudioData, setAuthorProfileAudioData] = useState([]);
  const [authorProfileFollowers, setAuthorProfileFollowersData] = useState([]);
  const [authorProfileFollowing, setAuthorProfileFollowingData] = useState([]);
  const [displayList, setDisplayList] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const searchTextParam = params.get('searchText');
  useEffect(() => {
    if (searchTextParam !== null) {
      setDisplayList(8);
    }
  }, [searchTextParam]);
  useEffect(() => {
    const existingData = userProfilePublicationsData;
    const existingLikedData = userProfileLiked;
    const existingSavedData = userProfileSaved;
    const existingDraftData = userProfileDraftData;
    const existingReviewData = userProfileReviewData;
    const existingApprovedData = userProfileApprovedData;
    const existingFollowData = authorProfileFollowers;
    const existingAudioData = authorProfileAudioData;
    const existingFollowingData = authorProfileFollowing;
    fetchUserProfileData(userId, setUserProfileData);
    if (displayList === 0)
      fetchUserProfilePublicationsData(page, userId, authorId, setTotalStories, setUserProfilePublicationsData, "all_stories", existingData, setHasMore);

    else if (displayList === 3)
      fetchUserProfileAudioData(page, userId, authorId, setTotalStories, setAuthorProfileAudioData, "audio", existingAudioData, setHasMore);

    else if (displayList === 1)
      fetchAuthorProfileFollowers(page, userId, setAuthorProfileFollowersData, existingFollowData, setHasMore);

    else if (displayList === 4)
      fetchUserProfileDraftData(page, userId, setTotalStories, setUserProfileDraftData, null, existingDraftData);
    else if (displayList === 5)
      fetchUserProfileSaved(page, userId, setTotalStories, setUserProfileSaved, null, existingSavedData, setHasMore);
    else if (displayList === 6)
      fetchUserProfileLiked(page, userId, setTotalStories, setUserProfileLiked, null, existingLikedData, setHasMore);
    else if (displayList === 7)
      fetchUserProfileReviewData(page, userId, setUserProfileReviewData, existingReviewData, setHasMore);
    else if (displayList === 8)
      fetchUserProfileApprovedData(searchTextParam, page, userId, setUserProfileApprovedData, existingApprovedData, setHasMore);
    else fetchAuthorProfileFollowing(page, userId, setAuthorProfileFollowingData, existingFollowingData, setHasMore);
  }, [page, displayList])

  return (
    <div>
      <Header
      />
      <div className='AuthorCardMainSection'>
        <div className='AuthorCardFirstSection'>
          <UserCard setPage={setPage} data={userProfileData} authorId={authorId} setData={setUserProfileData} setDisplayList={setDisplayList} displayList={displayList} />
          <UserSocialMediaCard
            profileData={userProfileData}
          />
        </div>
        <div className='AuthorCardSecondSection'>
          {/* <AuthorPageSearchBar /> */}
          <UserDescriptionCard
            profileData={userProfileData}
            authToken={authToken} />

          <UserPublications
            Count={userProfileData}
            userData={userProfilePublicationsData}

            profileData={userProfileDraftData}
            likedData={userProfileLiked}
            savedData={userProfileSaved}
            page={page} totalStories={totalStories} setPage={setPage} audioData={authorProfileAudioData}
            approved={userProfileApprovedData}
            review={userProfileReviewData}
            data={userProfileDraftData} displayList={displayList}
            authorFollowerData={authorProfileFollowers}
            authorFollowingData={authorProfileFollowing}
            hasMore={hasMore}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default UserProfile